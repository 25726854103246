.#{shared-var("LogoListBS")} {
  @extend .mx-auto;
  @extend .mb-4;
  // @extend .py-4;
  // @extend .border-top;
  // @extend .border-bottom;
  @extend .justify-content-around;
  @extend .align-items-center;

  &-wrapper {
    @extend .px-0;
  }

  &-header {
    .title {
      @extend .font-weight-bold;
    }

    .show-more span {
      @extend .d-block;
      @extend .h-100;
      padding-top: 4px;
    }
  }

  &-item {
    @extend .my-2;
    @extend .h-100;
    @extend .d-flex;
    @extend .justify-content-center;
  }

  &.row > div {
    @extend .px-0;

    @include media-breakpoint-up(xl) {
      &.col-xl-2 {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }

    picture > div {
      min-width: unset !important;
      max-width: unset !important;
      @extend .w-100;
      @extend .h-100;

      img {
        @extend .w-100;
        @extend .h-100;
        @extend .px-0;

        min-width: unset !important;
        max-width: unset !important;
      }
    }

    @include media-breakpoint-up(md) {
      &:not(:last-child) {
        padding-right: $spacer * 0.5 !important;
      }
    }
    @include media-breakpoint-down(sm) {
      &:nth-child(odd) {
        padding-right: $spacer * 0.5 !important;
      }
    }
  }

  .#{shared-var("LogoListBS")}-item {
    @extend .mt-0;
    @extend .mb-1;
  }

  .loading {
    min-height: unset !important;
    div:not([role="status"]) {
      height: 70px !important;
    }
  }

  img {
    object-fit: cover !important;
  }

  .carousel-inner {
    @extend .px-3;
  }

  .carousel-control-prev,
  .carousel-control-next {
    @include media-breakpoint-up(lg) {
      div.rounded:hover {
        background-color: rgba(0, 0, 0, 0.2) !important;
      }
      color: unset !important;
    }

    div {
      @extend .bg-transparent;
    }

    & > div {
      @extend .p-0;
      @include media-breakpoint-down(lg) {
        background-color: rgba(0, 0, 0, 0.2) !important;
      }
    }

    width: 2rem;

    background-color: rgba(0, 0, 0, 0.2);
  }
}
